import React, { useContext } from 'react';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import { json } from '../config/json';
import { FirebaseContext } from '../context/Firebase';
import SurveyContext from '../context/SurveyContext';
import { doc, setDoc } from 'firebase/firestore';
import ReactModal from 'react-modal';
import axios from 'axios';

function SurveyComponent({ showSurvey }) {
  const { app, database } = useContext(FirebaseContext);
  const { mailContext, setShowSurvey } = useContext(SurveyContext);

  console.log('database context:', database, app);
  const survey = new Model(json);
  survey.onComplete.add(async (sender, options) => {
    console.log(mailContext);
    console.log(sender.data);
    try {
      await setDoc(doc(database, 'surveys', mailContext), sender.data);
      axios.post('https://survey-waitlist-backend.onrender.com/send-email', {
        mail: mailContext,
        survey: sender.data,
      });
      setShowSurvey(false);
    } catch (e) {
      console.log(e);
    }
  });
  return (
    <ReactModal
      isOpen={showSurvey}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={() => setShowSurvey(false)}
    >
      <Survey model={survey} />
    </ReactModal>
  );
}

export default SurveyComponent;
