export const json = {
  locale: 'sv',
  title: 'LINKUP',
  description: 'Please complete the questionnaire below',
  logoPosition: 'right',
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'radiogroup',
          name: 'Följer du influencers på sociala medier?',
          title: 'Följer du influencers på sociala medier?',
          isRequired: true,
          choices: [
            {
              value: 'JA',
              text: 'JA',
            },
            {
              value: 'NEJ',
              text: 'NEJ',
            },
            {
              value: 'VET INTE',
              text: 'VET INTE',
            },
          ],
        },
      ],
    },
    {
      name: 'page2',
      elements: [
        {
          type: 'radiogroup',
          name: 'Följer du fler manliga, kvinnliga eller lika många influencers?',
          title:
            'Följer du fler manliga, kvinnliga eller lika många influencers?',
          isRequired: true,
          choices: [
            {
              value: 'MANLIGA',
              text: 'MANLIGA',
            },
            {
              value: 'KVINNLIGA',
              text: 'KVINNLIGA',
            },
            {
              value: 'LIKA MÅNGA',
              text: 'LIKA MÅNGA',
            },
          ],
        },
      ],
    },
    {
      name: 'page3',
      elements: [
        {
          type: 'text',
          name: 'Vilka typer av influencers följer du på sociala medier?',
          title: 'Vilka typer av influencers följer du på sociala medier?',
          isRequired: true,
          placeholder: 'Mode, skönhet, fitness, teknik osv.',
        },
      ],
    },
    {
      name: 'page4',
      elements: [
        {
          type: 'boolean',
          name: 'Har du någonsin försökt att kontakta en influencer direkt på sociala medier? Om ja, varför?',
          title:
            'Har du någonsin försökt att kontakta en influencer direkt på sociala medier? Om ja, varför?',
          isRequired: true,
          showCommentArea: true,
          swapOrder: true,
        },
      ],
    },
    {
      name: 'page5',
      elements: [
        {
          type: 'radiogroup',
          name: 'Skulle du vilja komma i kontakt med influencers?',
          title: 'Skulle du vilja komma i kontakt med influencers?',
          isRequired: true,
          choices: [
            {
              value: 'JA',
              text: 'JA',
            },
            {
              value: 'NEJ',
              text: 'NEJ',
            },
            {
              value: 'KANSKE',
              text: 'KANSKE',
            },
          ],
        },
      ],
    },
    {
      name: 'page6',
      elements: [
        {
          type: 'radiogroup',
          name: 'Finns det någon speciell person du skulle vilja ha kontakt med?',
          title:
            'Finns det någon speciell person du skulle vilja ha kontakt med?',
          isRequired: true,
          choices: [
            {
              value: 'JA',
              text: 'JA',
            },
            {
              value: 'NEJ',
              text: 'NEJ',
            },
            {
              value: 'KANSKE',
              text: 'KANSKE',
            },
          ],
        },
      ],
    },
    {
      name: 'page7',
      elements: [
        {
          type: 'comment',
          name: 'Vad är din huvudsakliga anledning till att vilja komma i kontakt med influencers eller profiler inom din bransch?',
          title:
            'Vad är din huvudsakliga anledning till att vilja komma i kontakt med influencers eller profiler inom din bransch?',
          isRequired: true,
          placeholder:
            'JAG VILL HA SVAR PÅ EN FRÅGA-JAG VILL GE BERÖM- FÖLJA TILLBAKA-ANSWER FREELY',
        },
      ],
    },
    {
      name: 'page8',
      elements: [
        {
          type: 'radiogroup',
          name: 'Skulle du vilja komma i kontakt med personer inom din bransch som inte nödvändigtvis har stora sociala mediekonton?',
          title:
            'Skulle du vilja komma i kontakt med personer inom din bransch som inte nödvändigtvis har stora sociala mediekonton?',
          isRequired: true,
          choices: [
            {
              value: 'JA',
              text: 'JA',
            },
            {
              value: 'NEJ',
              text: 'NEJ',
            },
            {
              value: 'KANSKE',
              text: 'KANSKE',
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          type: 'comment',
          name: 'Vad skulle vara den största fördelen för dig om du kunde få kontakt med influencers eller experter inom din bransch?',
          title:
            'Vad skulle vara den största fördelen för dig om du kunde få kontakt med influencers eller experter inom din bransch?',
          isRequired: true,
          placeholder:
            'RÅD OCH TIPS-ETABLERA EN KONTAKT-FÖLJA TILLBAKA-ANSWER FREELY',
        },
      ],
    },
    {
      name: 'page10',
      elements: [
        {
          type: 'comment',
          name: 'Vilka hinder eller svårigheter ser du när det gäller att kommunicera med influencers eller experter?',
          title:
            'Vilka hinder eller svårigheter ser du när det gäller att kommunicera med influencers eller experter?',
          isRequired: true,
          placeholder: 'FÅR ADLRIG SVAR-VÅGAR INTE-VET INTE',
        },
      ],
    },
    {
      name: 'page11',
      elements: [
        {
          type: 'radiogroup',
          name: 'Önskar du att du hade fler kontakter för att kunna förverkliga din dröm?',
          title:
            'Önskar du att du hade fler kontakter för att kunna förverkliga din dröm?',
          isRequired: true,
          choices: [
            {
              value: 'JA',
              text: 'JA',
            },
            {
              value: 'NEJ',
              text: 'NEJ',
            },
            {
              value: 'VEN INTE',
              text: 'VEN INTE',
            },
          ],
        },
      ],
    },
    {
      name: 'page12',
      elements: [
        {
          type: 'radiogroup',
          name: 'Önskar du att du kunde komma i kontakt med någon som skulle kunna inspirera dig?',
          title:
            'Önskar du att du kunde komma i kontakt med någon som skulle kunna inspirera dig?',
          isRequired: true,
          choices: [
            {
              value: 'JA',
              text: 'JA',
            },
            {
              value: 'NEJ',
              text: 'NEJ',
            },
            {
              value: 'KANKSE',
              text: 'KANKSE',
            },
          ],
        },
      ],
    },
    {
      name: 'page13',
      elements: [
        {
          type: 'radiogroup',
          name: 'Får du inspiration av andra människor som du anser har nått framgång i din bransch eller annan bransch?',
          title:
            'Får du inspiration av andra människor som du anser har nått framgång i din bransch eller annan bransch?',
          isRequired: true,
          choices: [
            {
              value: 'JA',
              text: 'JA',
            },
            {
              value: 'NEJ',
              text: 'NEJ',
            },
            {
              value: 'KANSKE',
              text: 'KANSKE',
            },
          ],
        },
      ],
    },
    {
      name: 'page14',
      elements: [
        {
          type: 'radiogroup',
          name: 'Skulle du kunna tänka dig att betala för att komma i kontakt med en framgångsrik person, influenser eller expert?',
          title:
            'Skulle du kunna tänka dig att betala för att komma i kontakt med en framgångsrik person, influenser eller expert?',
          isRequired: true,
          choices: [
            {
              value: 'JA',
              text: 'JA',
            },
            {
              value: 'NEJ',
              text: 'NEJ',
            },
            {
              value: 'KANSKE',
              text: 'KANSKE',
            },
          ],
        },
      ],
    },
    {
      name: 'page15',
      elements: [
        {
          type: 'radiogroup',
          name: 'Hur länge skulle du vilja prata med personen?',
          title: 'Hur länge skulle du vilja prata med personen?',
          isRequired: true,
          choices: [
            {
              value: '15 MINUER',
              text: '15 MINUER',
            },
            {
              value: '30 MINUTER',
              text: '30 MINUTER',
            },
            {
              value: '60 MINUTER ELLER MER',
              text: '60 MINUTER ELLER MER',
            },
          ],
        },
      ],
    },
    {
      name: 'page16',
      elements: [
        {
          type: 'radiogroup',
          name: 'Vilket kommunikationsformat skulle du föredra för att prata med personen?',
          title:
            'Vilket kommunikationsformat skulle du föredra för att prata med personen?',
          isRequired: true,
          choices: [
            {
              value: 'FACETIME',
              text: 'FACETIME',
            },
            {
              value: 'TEXMEDDELANDE',
              text: 'TEXMEDDELANDE',
            },
            {
              value: 'TELEFONSAMTAL',
              text: 'TELEFONSAMTAL',
            },
          ],
        },
      ],
    },
    {
      name: 'page17',
      elements: [
        {
          type: 'radiogroup',
          name: 'Har du tidigare skrivit till någon med ett stort antal följare(50tusen följare eller fler)?',
          title:
            'Har du tidigare skrivit till någon med ett stort antal följare(50tusen följare eller fler)?',
          isRequired: true,
          choices: [
            {
              value: 'JA',
              text: 'JA',
            },
            {
              value: 'NEJ',
              text: 'NEJ',
            },
            {
              value: 'VET INTE',
              text: 'VET INTE',
            },
          ],
        },
      ],
    },
    {
      name: 'page18',
      elements: [
        {
          type: 'radiogroup',
          name: 'Har du fått svar från någon med ett stort antal följare(50tusen följare eller mer)?',
          title:
            'Har du fått svar från någon med ett stort antal följare(50tusen följare eller mer)?',
          isRequired: true,
          choices: [
            {
              value: 'JA',
              text: 'JA',
            },
            {
              value: 'NEJ',
              text: 'NEJ',
            },
            {
              value: 'VET INTE',
              text: 'VET INTE',
            },
          ],
        },
      ],
    },
    {
      name: 'page19',
      elements: [
        {
          type: 'radiogroup',
          name: 'Har du tidigare skrivit till någon profil/expert inom din bransch?',
          title:
            'Har du tidigare skrivit till någon profil/expert inom din bransch?',
          isRequired: true,
          choices: [
            {
              value: 'JA',
              text: 'JA',
            },
            {
              value: 'NEJ',
              text: 'NEJ',
            },
            {
              value: 'KANGKE',
              text: 'KANGKE',
            },
          ],
        },
      ],
    },
    {
      name: 'page20',
      elements: [
        {
          type: 'radiogroup',
          name: 'Har du fått svar från någon profil/expert?',
          title: 'Har du fått svar från någon profil/expert?',
          isRequired: true,
          choices: [
            {
              value: 'JA',
              text: 'JA',
            },
            {
              value: 'NEJ',
              text: 'NEJ',
            },
            {
              value: 'KANSKE',
              text: 'KANSKE',
            },
          ],
        },
      ],
    },
    {
      name: 'page21',
      elements: [
        {
          type: 'radiogroup',
          name: 'Vad kulle du vara villig att betala för ett telefonsamtal med en framgångsrik person, influenser eller expert?',
          title:
            'Vad kulle du vara villig att betala för ett telefonsamtal med en framgångsrik person, influenser eller expert?',
          isRequired: true,
          choices: [
            {
              value: '100KR',
              text: '100KR',
            },
            {
              value: '500KR',
              text: '500KR',
            },
            {
              value: '1000KR ELLER MER',
              text: '1000KR ELLER MER',
            },
          ],
        },
      ],
    },
    {
      name: 'page22',
      elements: [
        {
          type: 'radiogroup',
          name: 'Vad skulle du betala för ett FaceTime-samtal med en framgångsrik person, influenser eller expert?',
          title:
            'Vad skulle du betala för ett FaceTime-samtal med en framgångsrik person, influenser eller expert?',
          isRequired: true,
          choices: [
            {
              value: '100KR',
              text: '100KR',
            },
            {
              value: '500KR',
              text: '500KR',
            },
            {
              value: '1000KR ELLER MER',
              text: '1000KR ELLER MER',
            },
          ],
        },
      ],
    },
    {
      name: 'page23',
      elements: [
        {
          type: 'radiogroup',
          name: 'Vad skulle du betala för ett textsamtal med en framgångsrik person, influenser eller expert?',
          title:
            'Vad skulle du betala för ett textsamtal med en framgångsrik person, influenser eller expert?',
          isRequired: true,
          choices: [
            {
              value: '100KR',
              text: '100KR',
            },
            {
              value: '500KR',
              text: '500KR',
            },
            {
              value: '1000KR ELLER MER',
              text: '1000KR ELLER MER',
            },
          ],
        },
      ],
    },
    {
      name: 'page24',
      elements: [
        {
          type: 'comment',
          name: 'Vem skulle du vilja prata med som du har försökt komma i kontakt med?',
          title:
            'Vem skulle du vilja prata med som du har försökt komma i kontakt med?',
          isRequired: true,
        },
      ],
    },
  ],
};
